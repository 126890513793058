import { TableBody as Body, TableCell, TableRow } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import {
  Delete as DeleteIcon,
  CloudDownloadOutlined as DownloadIcon,
  Edit as EditIcon,
  InsertDriveFile as FileIcon,
  ShareOutlined as GraphIcon,
  BackupOutlined as UploadIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import Tooltip from 'antd/es/tooltip';
import _ from 'lodash';
import React, { FC } from 'react';
import constants from 'shared/constants';
import { EDataScienceGraph, EDataTableLayout } from 'shared/enum/data-table.enum';
import { ELocalStorage } from 'shared/enum/local-storage.type';
import styled from 'styled-components';
import { LargeText } from '..';
import IconBankAccount from '../../../assets/images/icon/Ic_bank_account.svg';
import IconCaseFileRecord from '../../../assets/images/icon/Ic_case_file_record.svg';
import IconGraph from '../../../assets/images/icon/Ic_graph.svg';
import IconMobileNumber from '../../../assets/images/icon/Ic_mobile_number.svg';
import IconHistory from '../../../assets/images/icon/history.svg';
import Checkbox from '../Checkbox';
import TableContext from './TableContext';

const TableLoadingRow = styled(TableRow)`
  border-bottom: none;
`;
const TableLoadingColumn = styled(TableCell)`
  border-bottom: none !important;
`;
const TableBodyRow = styled(TableRow)<{ select?: string }>`
  border-bottom: 2px solid ${constants.LIGHT_BLUE};
  .MuiTableCell-root {
    padding: 5px 5px 5px 0px;
  }
`;
// ${(props) => (props.width ? `width: ${props.width}` : `width: ${EDataTableLayout.WIDTHSMALL}`)};
const TableBodyColumn = styled(TableCell)<{ select?: string }>`
  ${(props) => (props.select ? `border: 2px solid ${props.select}` : ``)}
`;
const TableBodyActionColumn = styled(TableBodyColumn)`
  width: ${EDataTableLayout.WIDTHSMALL};
`;
const TableBodyText = styled(LargeText)<{ overFlow?: boolean; width?: string; color?: string }>`
  /* display: flex; */
  max-width: ${(props) => (props.width ? props.width : '100ch')};
  /* กำหนด max-width ตาม props หรือใช้ค่าเริ่มต้น */
  /* min-width: 150px; */
  /* max-width: 100ch; */
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => (props.width ? `min-width: ${props.width}` : `min-width: ${EDataTableLayout.WIDTHSMALL}`)};
  ${(props) => (props.overFlow ? `max-width: max-content` : ``)};
  ${(props) => (props.overFlow ? `overflow: unset` : `overflow: hidden`)};
  ${(props) => (props.color ? `color: ${props.color};` : '')};
  @media (max-width: 1480px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
const TableActionWrapper = styled.div<{ tab: number }>`
  margin-left: auto;
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: repeat(${(props) => props.tab}, max-content); // 3

  > svg {
    cursor: pointer;
  }

  > div {
    width: 1em;
    height: 1em;
  }
`;

const ItemIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const comparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  else if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'asc' ? (a, b) => comparator(a, b, orderBy) : (a, b) => -comparator(a, b, orderBy);
};

const stableOrder = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const TableBody: FC = () => (
  <TableContext.Consumer>
    {({
      columns,
      isLoading,
      items,
      spaceCount,
      fixCheckbox,
      disabled,
      onGraph,
      onHeadline,
      onDownload,
      onUpload,
      onFile,
      onView,
      onEdit,
      onDelete,
      onGraphDS,
      onMobile,
      onBank,
      onItem,
      onCheckBox,
      onViewForm,
      onEditForm,
      onModal,
      onSwitch,
      onHistory,
      hideOnView = false,
      hideOnEdit = false,
      hideOnViewForm = false,
      hideOnEditForm = false,
      hideOnDelete = false,
      hideOnExport = false,
      hideOnCheck = false,
      hideOnSwitch = false,
      order,
      orderBy,
      placeholder,
      onDownloadFileByType,
    }) => (
      <Body>
        {isLoading ? (
          <TableLoadingRow>
            <TableLoadingColumn>
              <TableBodyText>{'...กำลังโหลด'}</TableBodyText>
            </TableLoadingColumn>
          </TableLoadingRow>
        ) : (
          <>
            {_.isEmpty(items) ? (
              <TableLoadingRow>
                <TableLoadingColumn>
                  <TableBodyText color="red">{placeholder || 'ไม่พบข้อมูล'}</TableBodyText>
                </TableLoadingColumn>
              </TableLoadingRow>
            ) : (
              <>
                {stableOrder(items, getComparator(order, orderBy)).map((item: any) => {
                  return (
                    <TableBodyRow key={item.id} id={ELocalStorage.PREVIOUS_SELECTED + item.id}>
                      {hideOnCheck ? (
                        <>
                          {(item.grahpPermission &&
                            onCheckBox &&
                            (item.processStatus === EDataScienceGraph.SUCCESS ||
                              item.processStatus === EDataScienceGraph.WAIT)) ||
                          fixCheckbox ? (
                            <TableBodyColumn width={EDataTableLayout.WIDTHSMALL}>
                              <TableBodyText>
                                <Checkbox
                                  checked={item.checkBox}
                                  onChange={() => {
                                    onCheckBox(item);
                                  }}
                                />
                              </TableBodyText>
                            </TableBodyColumn>
                          ) : (
                            <TableBodyColumn width={EDataTableLayout.WIDTHSMALL}>
                              <TableBodyText>
                                <></>
                              </TableBodyText>
                            </TableBodyColumn>
                          )}
                        </>
                      ) : (
                        <>
                          {(onCheckBox &&
                            (item.processStatus === EDataScienceGraph.SUCCESS ||
                              item.processStatus === EDataScienceGraph.WAIT)) ||
                            (fixCheckbox && (
                              <TableBodyColumn width={EDataTableLayout.WIDTHSMALL}>
                                <TableBodyText>
                                  <Checkbox
                                    checked={item.checkBox}
                                    onChange={() => {
                                      onCheckBox(item);
                                    }}
                                  />
                                </TableBodyText>
                              </TableBodyColumn>
                            ))}
                        </>
                      )}
                      {columns.map((column, index) => {
                        const columnContent = (
                          <TableBodyColumn
                            key={column.key}
                            align={column.align}
                            width={column.width}
                            select={!_.isEmpty(item.color) ? item.color[index] : ''}
                          >
                            {/(\\n)/g.test(item[column.key]) ? (
                              <TableBodyText overFlow={true} width={column.width}>
                                <>
                                  {item[column.key].split('\\n').map((line, lineIndex) => (
                                    <React.Fragment key={lineIndex}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </>
                              </TableBodyText>
                            ) : (
                              <TableBodyText width={column.width}>{item[column.key]}</TableBodyText>
                            )}
                          </TableBodyColumn>
                        );

                        // Check length of the string

                        return column.tooltip && column.width ? (
                          <Tooltip
                            key={column.key}
                            title={item[column.key]}
                            align={column.align}
                            overlayStyle={{ fontSize: '20px' }}
                          >
                            {columnContent}
                          </Tooltip>
                        ) : (
                          columnContent
                        );
                      })}
                      {(onView ||
                        onViewForm ||
                        onEdit ||
                        onDelete ||
                        onGraph ||
                        onDownload ||
                        onFile ||
                        onUpload ||
                        onGraphDS ||
                        onMobile ||
                        onBank ||
                        onModal ||
                        onSwitch ||
                        onEditForm ||
                        onHistory ||
                        onDownloadFileByType) && (
                        <TableBodyActionColumn>
                          <TableActionWrapper tab={onItem || 3}>
                            {hideOnView ? (
                              <>{item.viewPermission && onView && <VisibilityIcon onClick={() => onView(item.id)} />}</>
                            ) : (
                              <>{onView && <VisibilityIcon onClick={() => onView(item.id)} />}</>
                            )}
                            {hideOnViewForm ? (
                              <>
                                {item.viewPermission && onViewForm && (
                                  <VisibilityIcon onClick={() => onViewForm(item)} />
                                )}
                              </>
                            ) : (
                              <>{onViewForm && <VisibilityIcon onClick={() => onViewForm(item)} />}</>
                            )}
                            {hideOnEdit ? (
                              <>{item.editPermission && onEdit && <EditIcon onClick={() => onEdit(item.id)} />}</>
                            ) : (
                              <>{onEdit && <EditIcon onClick={() => onEdit(item.id)} />}</>
                            )}
                            {hideOnEditForm ? (
                              <>{item.editPermission && onEditForm && <EditIcon onClick={() => onEditForm(item)} />}</>
                            ) : (
                              <>{onEditForm && <EditIcon onClick={() => onEditForm(item)} />}</>
                            )}
                            {/* order */}
                            {onGraph && item.isShow && <GraphIcon onClick={() => onGraph(item.id)} />}
                            {onHeadline && item.isShow && <GraphIcon onClick={() => onHeadline(item)} />}
                            {/* {onDownload && item.filePath && <DownloadIcon onClick={() => onDownload(item.filePath)} />} */}
                            {hideOnExport ? (
                              <>
                                {item.exportPermission && item.filePath && onDownload && (
                                  <DownloadIcon onClick={() => onDownload(item.filePath)} />
                                )}
                              </>
                            ) : (
                              <>
                                {item.filePath && onDownload && (
                                  <DownloadIcon onClick={() => onDownload(item.filePath)} />
                                )}
                              </>
                            )}
                            {onUpload && <UploadIcon onClick={() => onUpload(item.id)} />}
                            {onFile && <FileIcon onClick={() => onFile(item.id)} />}
                            {onGraphDS && <ItemIcon src={IconGraph} onClick={() => onGraphDS(item)} />}
                            {onMobile && item.phoneNo && (
                              <ItemIcon src={IconMobileNumber} onClick={() => onMobile(item)} />
                            )}
                            {onBank && item.bankAccount && (
                              <ItemIcon src={IconBankAccount} onClick={() => onBank(item)} />
                            )}
                            {onModal && <ItemIcon src={IconCaseFileRecord} onClick={() => onModal(item)} />}
                            {hideOnSwitch ? (
                              <>
                                {item.editPermission && onSwitch && (
                                  <Switch checked={item.userStatusID} onClick={() => onSwitch(item)} />
                                )}
                              </>
                            ) : (
                              <>{onSwitch && <Switch checked={item.userStatusID} onClick={() => onSwitch(item)} />}</>
                            )}
                            {/* order */}
                            {hideOnDelete ? (
                              <>
                                {item.deletePermission && onDelete && <DeleteIcon onClick={() => onDelete(item.id)} />}
                              </>
                            ) : (
                              <>{onDelete && <DeleteIcon onClick={() => onDelete(item.id)} />}</>
                            )}
                            {onHistory && <FileIcon onClick={() => onHistory(item.id)} />}
                            {onDownloadFileByType && <DownloadIcon onClick={() => onDownloadFileByType(item)} />}
                          </TableActionWrapper>
                        </TableBodyActionColumn>
                      )}
                    </TableBodyRow>
                  );
                })}
              </>
            )}
          </>
        )}
      </Body>
    )}
  </TableContext.Consumer>
);

export default TableBody;
