import { Grid, IconButton } from '@material-ui/core';
import _ from 'lodash';
import ArrestFormContext from 'pages/Arrest/Form/ArrestFormContext';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polygon,
  StreetViewPanorama,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';
import { useDispatch, useSelector } from 'react-redux';
import { withProps } from 'recompose';
import { EArrestViewMode } from 'shared/modules/arrest';
import {
  IGoogleMap,
  IGoogleMapValidate,
  INITIAL_GOOGLE_MAP,
  INITIAL_GOOGLE_MAP_VALIDATE,
} from 'shared/modules/searching-report';
import usePermission from 'shared/utils/usePermission';
import { IReducer } from 'store';
import { getPlaceNameByCoords, getPlaceNameByCoordsNearby } from 'store/google-maps/google-maps.action';
import styled from 'styled-components';
import { Button, LargeText } from '..';
import PageLoading from '../PageLoding';
import { INITIAL_COORDS } from '../constant/google-maps.constant';
import { TCoords } from '../type/google-maps.type';
// import { validateMap } from './latlng.validate';
import SearchAutocomplete from './SearchAutocomplete';
import { iconType, openType, titleType } from './utils/iconType';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyA15aVE-VpoUIM-ala-pMupQOuoYxWzy4E';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyD8E2N2D9N8lnMoyQiwN-eDoivtE-8HRCI';
// const GOOGLE_MAPS_API_KEY = ' AIzaSyCkgXuCpP3BauX9EIlnHlqtC8IP1SUoYbw'; //api_key_nut
// const GOOGLE_MAPS_API_KEY = 'AIzaSyC-0I0RAWjHmRfkDHk3PfiII9EcYVnPuo0';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyBsiMhiER5RjkCm9W1S6UPaMt3T2RfbNuI';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyAdNE-XHHFtQx4DzF6g5kS7YaXgAqIJ66g';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyDc8PU-b66jP3xLxQ8LpBb99OjPa9F4Kxs';
const GOOGLE_MAPS_API_KEY = 'AIzaSyDwKs5XAmH0wjPpvIQYyfB7flm0Et0Ezq';

const StyledGoogleMap = styled(GoogleMap)`
  width: 100%;
  sposition: relative;
`;
const InfoDetail = styled.div`
  white-space: pre;
`;
const ContainType = styled.div`
  max-height: 9rem;
  display: flex;
  flex-direction: column;
`;
const ItemType = styled.div`
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
`;
const ButtonIconGroupStyle = styled.div`
  display: flex;
  height: 2rem;
`;
const LargeTextStyle = styled.span`
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
`;

const ButtonMapType = styled(Grid)`
  z-index: 10;
  position: absolute;
  margin-top: 1em !important;
  margin-left: 1em;
`;

const ButtonMarker = styled(Button)`
  margin: auto !important;
  background-color: #979494d1 !important;
  color: #000000;
  border-radius: 3px !important;
  width: 3em;
`;

interface IGoogleMapsProps {
  defaultZoom?: number;
  coords?: TCoords;
  isMarkerShow?: boolean;
  isMultiMarkerShow?: boolean;
  isHeatmapShow?: boolean;
  isMarkerAlwaysCenter?: boolean;
  onCoordsChange?(coords: TCoords): void;
  setheatMapData?;
  setMarkersShow?;
  autocompleteDisabled?: boolean;
  showAutocomplete?: boolean;
  place?: string;
  setPlace?(newPlace: string): void;
  errorState?: IGoogleMapValidate;
  setErrorState?: Dispatch<SetStateAction<IGoogleMapValidate>>;
  validateOnBlur?: (field: keyof IGoogleMapValidate) => (_event: React.FocusEvent<HTMLInputElement>) => void;
  latlng?: any;
  setLatlng?(latlng: any): void;
}

const GoogleMaps: FC<IGoogleMapsProps> = ({
  defaultZoom = 17,
  coords,
  isMarkerShow = true,
  isMultiMarkerShow = false,
  isHeatmapShow = false,
  isMarkerAlwaysCenter = true,
  onCoordsChange,
  setheatMapData = {},
  setMarkersShow = {},
  autocompleteDisabled,
  showAutocomplete = true,
  latlng,
  setLatlng,
  place,
  setPlace,
  errorState,
  setErrorState,
  validateOnBlur,
}) => {
  // state
  const [center, setCenter] = useState<TCoords>(INITIAL_COORDS);

  const dispatch = useDispatch();
  const isPlaceLoading = useSelector((store: IReducer) => store.googleMaps.isPlaceLoading);
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState<any>();
  const [positions, setPositions] = useState<IGoogleMap>(INITIAL_GOOGLE_MAP);
  // const [mapType, setMapType] = useState<string>(['satellite', 'roadmap']);
  const regexDate = /\d{1,2}\/\d{1,2}\/\d{4}/g;
  const [mapType, setMapType] = useState<string>('satellite');
  const { viewMode } = React.useContext(ArrestFormContext);
  const user = useSelector((store: IReducer) => store.user);
  const { viewPermission } = usePermission(1000020, user);

  const statesData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: '01',
        properties: { name: 'Alabama', density: 94.65 },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-87.359296, 35.00118],
              [-85.606675, 34.984749],
              [-85.431413, 34.124869],
              [-85.184951, 32.859696],
              [-85.069935, 32.580372],
              [-84.960397, 32.421541],
              [-85.004212, 32.322956],
              [-84.889196, 32.262709],
              [-85.058981, 32.13674],
              [-85.053504, 32.01077],
              [-85.141136, 31.840985],
              [-85.042551, 31.539753],
              [-85.113751, 31.27686],
              [-85.004212, 31.003013],
              [-85.497137, 30.997536],
              [-87.600282, 30.997536],
              [-87.633143, 30.86609],
              [-87.408589, 30.674397],
              [-87.446927, 30.510088],
              [-87.37025, 30.427934],
              [-87.518128, 30.280057],
              [-87.655051, 30.247195],
              [-87.90699, 30.411504],
              [-87.934375, 30.657966],
              [-88.011052, 30.685351],
              [-88.10416, 30.499135],
              [-88.137022, 30.318396],
              [-88.394438, 30.367688],
              [-88.471115, 31.895754],
              [-88.241084, 33.796253],
              [-88.098683, 34.891641],
              [-88.202745, 34.995703],
              [-87.359296, 35.00118],
            ],
          ],
        },
      },
    ],
  };

  // useeffect
  useEffect(() => {
    const locations: TCoords = {
      lat: parseFloat(center.lat.toFixed(6)),
      lng: parseFloat(center.lng.toFixed(6)),
    };
    setCenter(coords ? coords : locations);
    if (setLatlng)
      setLatlng(
        !place
          ? positions.lat === INITIAL_COORDS.lat && positions.lng === INITIAL_COORDS.lng
            ? ''
            : locations.lat.toFixed(6) + ',' + locations.lng.toFixed(6)
          : ''
      );
  }, [coords]);

  const onTypeMap = () => {
    if (mapType !== 'satellite') {
      setMapType('satellite');
    } else {
      setMapType('roadmap');
    }
  };

  const getPlaceByCoordsNearbySuccess = (response) => {
    console.log('🚀 : getPlaceByCoordsNearbySuccess : response', response);
  };

  // handlers
  const onMarkerDragEnd = async (event: any): Promise<void> => {
    const position: TCoords = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setCenter(isMarkerAlwaysCenter ? position : center);
    if (setLatlng) setLatlng(position.lat.toFixed(6) + ',' + position.lng.toFixed(6));
    if (onCoordsChange) {
      if (setPlace) {
        const getPlaceNameCallback = (placeName: string) => {
          setPlace(placeName);
          onCoordsChange(position);
        };
        setPlace('');
        dispatch(getPlaceNameByCoords(position, getPlaceNameCallback));
        // dispatch(getPlaceNameByCoordsNearby(position, getPlaceByCoordsNearbySuccess));
      } else {
        onCoordsChange(position);
      }
      onCoordsChange(position);
    }
  };

  const gradient = {
    0: [
      'rgba(102, 255, 0, 0)',
      'rgba(102, 255, 0, 1)',
      'rgba(147, 255, 0, 1)',
      'rgba(193, 255, 0, 1)',
      'rgba(238, 255, 0, 1)',
      'rgba(244, 227, 0, 1)',
      'rgba(249, 198, 0, 1)',
      'rgba(255, 170, 0, 1)',
      'rgba(255, 113, 0, 1)',
      'rgba(255, 57, 0, 1)',
      'rgba(255, 0, 0, 1)',
    ],
    1: [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 255, 255, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(0, 127, 255, 1)',
      'rgba(0, 63, 255, 1)',
      'rgba(0, 0, 255, 1)',
      'rgba(0, 0, 223, 1)',
      'rgba(0, 0, 191, 1)',
      'rgba(0, 0, 159, 1)',
      'rgba(0, 0, 127, 1)',
      'rgba(63, 0, 91, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(255, 0, 0, 1)',
    ],
    2: [
      'rgba(153, 0, 255, 0)',
      'rgba(153, 0, 255, 1)',
      'rgba(108, 0, 255, 1)',
      'rgba(62, 0, 255, 1)',
      'rgba(17, 0, 255, 1)',
      'rgba(11, 28, 255, 1)',
      'rgba(6, 57, 255, 1)',
      'rgba(0, 85, 255, 1)',
      'rgba(0, 142, 255, 1)',
      'rgba(0, 198, 255, 1)',
      'rgba(0, 255, 255, 1)',
    ],
  };

  var groupedHeatmapData: { [k: string]: any } = {};
  var groupedMarkerHeatMapData: { [k: string]: any } = {};

  if (isHeatmapShow || isMultiMarkerShow) {
    var heatMapData: object[] = [];
    var markerHeatMapData: object[] = [];

    setheatMapData &&
      setheatMapData.map((val, index) => {
        if (val.latitude_situation && val.longitude_situation) {
          heatMapData.push({
            situation: val.situation,
            location: new window.google.maps.LatLng(val.latitude_situation, val.longitude_situation),
            // location: new window.google.maps.LatLng(
            //   13.868468274152848 + index * 0.001,
            //   100.58071611771162 + index * 0.001
            // ),
            weight: val.score,
          });
          markerHeatMapData.push({
            situation: val.situation,
            detail: val.detail,
            lat: val.latitude_situation,
            lng: val.longitude_situation,
            type: val.type,
            id: val.id,
            spotName: val.spotName,
          });
        }
      });
    groupedHeatmapData = _.mapValues(_.groupBy(heatMapData, 'situation'), (heatMapDatalist) =>
      heatMapDatalist.map((heat) => _.omit(heat, 'situation'))
    );
    groupedMarkerHeatMapData = _.mapValues(_.groupBy(markerHeatMapData, 'situation'), (groupedHeatmapDatalist) =>
      groupedHeatmapDatalist.map((markerHeat) => _.omit(markerHeat, 'situation'))
    );
  }

  const markerColor = {
    0: 'https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1',
    1: 'https://mt.google.com/vt/icon?color=ff004C13&name=icons/spotlight/spotlight-waypoint-blue.png',
    2: 'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2',
  };

  const handleToggleOpen = (markerId) => {
    // console.log('markerId', markerId);
    setOpenInfoWindowMarkerId(markerId);
  };

  const groupedMarkerHeatMapDataByLatLng = React.useMemo(() => {
    const groupByLatLng: any[] = [];
    const group = Object.values(groupedMarkerHeatMapData).map((item) =>
      Object.values(_.groupBy(item, (itemA) => `${itemA.lat}+${itemA.lng}`)).map((itemB) => {
        const data = {
          ...itemB[0],
          group: itemB,
        };
        groupByLatLng.push(data);
        return data;
      })
    );
    const groupByLatLngs = _.groupBy(groupByLatLng, (item) => `${item.lat}+${item.lng}`);
    const formatGroup = group.map((item) => {
      return item.map((itemA) => {
        return {
          ...itemA,
          group: groupByLatLngs[`${itemA.lat}+${itemA.lng}`].map((itemB) => {
            return { [itemB.type]: itemB.group };
          }),
        };
      });
    });
    return formatGroup;
  }, [groupedMarkerHeatMapData]);

  const onSubmit = () => {
    const setLatLng = latlng?.split(',');
    const position: TCoords = {
      lat: parseFloat(setLatLng[0]),
      lng: parseFloat(setLatLng[1]),
    };
    setCenter(position);
    if (onCoordsChange) {
      onCoordsChange(position);
    }
  };

  return (
    <>
      <PageLoading loading={isPlaceLoading} />
      {showAutocomplete && (
        <SearchAutocomplete
          disabled={autocompleteDisabled}
          setCoords={onCoordsChange ? onCoordsChange : () => {}}
          place={place}
          setPlace={setPlace}
          positions={positions}
          setPositions={setPositions}
          onSubmit={onSubmit}
          errorState={errorState}
          setErrorState={setErrorState}
          validateOnBlur={validateOnBlur}
          latlng={latlng}
          setLatlng={setLatlng}
        />
      )}
      <div id="pano"></div>
      <div className="google-maps">
        <StyledGoogleMap
          defaultOptions={{
            disableDefaultUI: true,
            gestureHandling: 'greedy',
            streetViewControl: true, // เปิดใช้งานตัวควบคุม Pegman
            streetViewControlOptions: {
              position: google.maps.ControlPosition.RIGHT_TOP, // ตั้งค่าตำแหน่งของ Pegman
            },
            // mapTypeControl: true, // เปิดใช้งานตัวควบคุมประเภทแผนที่
            // mapTypeControlOptions: {
            //   style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            //   position: google.maps.ControlPosition.TOP_RIGHT,
            // },
            // fullscreenControl: true,
          }}
          defaultZoom={defaultZoom}
          center={center}
          // streetView
          // streetView={undefined}
          //streetView={streetView}
          mapTypeId={mapType}

          // defaultMapTypeId={''}
        >
          <ButtonMapType>
            <ButtonMarker onClick={onTypeMap}>{mapType === 'roadmap' ? 'แผนที่' : 'ดาวเทียม'}</ButtonMarker>
          </ButtonMapType>
          {isMarkerShow && (
            <Marker
              draggable={true}
              onDragEnd={onMarkerDragEnd}
              position={center}
              // options={{ icon: markerColor[2] }}
            />
          )}
          {isMultiMarkerShow &&
            setMarkersShow.map((value, index) => {
              return (
                <>
                  {!!value.latitude_license_plate && value.longitude_license_plate && (
                    <Marker
                      key={'Marker1_' + index}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                      options={{ icon: markerColor[0] }}
                      position={{ lat: value.latitude_license_plate, lng: value.longitude_license_plate }}
                    >
                      <InfoWindow>
                        <div>{value.license_plate_no}</div>
                      </InfoWindow>
                    </Marker>
                  )}
                  {!!value.latitude_mobile && !!value.longitude_mobile && (
                    <Marker
                      key={'Marker2_' + index}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                      options={{ icon: markerColor[1] }}
                      position={{ lat: value.latitude_mobile, lng: value.longitude_mobile }}
                    >
                      <InfoWindow>
                        <div>{value.mobile_no}</div>
                      </InfoWindow>
                    </Marker>
                  )}
                </>
              );
            })}
          {isHeatmapShow &&
            Object.values(groupedHeatmapData).map((value, index) => {
              return (
                <HeatmapLayer
                  key={'HeatmapLayer' + index}
                  data={value}
                  options={{
                    data: value,
                    gradient: gradient[index],
                    radius: 20,
                    maxIntensity: 2, // กำหนดค่าความเข้มสูงสุด
                  }}
                >
                  {/* {
              statesData?.features?.map((state) => {
                const dataPolygon = state.geometry.coordinates[0]?.map((item) => [item[1], item[0]]);
                console.log(dataPolygon[0])
              return (
                <Polygon
                  defaultOptions={{
                    fillColor: 'red',
                    fillOpacity: 1.7,
                    weight: 5,
                    opacity: 5,
                    dashArray: 5,
                    color: 'black'
                  }}
                  // options={data}
                  // paths={dataPolygon}
                  paths={value}
                />
              ) */}
                  {/* })
               } */}
                </HeatmapLayer>
              );
            })}

          {/* // NOTE: tooltip marker in map */}
          {isMultiMarkerShow &&
            Object.values(groupedMarkerHeatMapDataByLatLng).map((value, index) => {
              // Object.values(groupedMarkerHeatMapData).map((value, index) => {
              return value.map((val, i) => {
                return (
                  <Marker
                    key={'MarkerHeat' + i}
                    draggable={true}
                    onDragEnd={onMarkerDragEnd}
                    options={{ icon: markerColor[index] }}
                    position={{ lat: val.lat, lng: val.lng }}
                    onClick={() => handleToggleOpen('window_' + index + '_' + i)}
                  >
                    {viewPermission && (
                      <>
                        {openInfoWindowMarkerId && openInfoWindowMarkerId === 'window_' + index + '_' + i && (
                          <InfoWindow>
                            <InfoDetail>
                              {val.lat.toFixed(6)}
                              {', '}
                              {val.lng.toFixed(6)}
                              {/* <br /> */}
                              {val.type && (
                                <ContainType>
                                  {val.group.map((item) => (
                                    <>
                                      {Object.values(item).map((itemB: any) => (
                                        <>
                                          {titleType(itemB[0].type)}
                                          <ItemType>
                                            {itemB.map((itemC) => (
                                              <ButtonIconGroupStyle>
                                                <IconButton onClick={() => openType(itemC.id, itemC.type)}>
                                                  {iconType(itemC.type)}
                                                </IconButton>
                                                <LargeTextStyle>
                                                  {_.some(itemC.detail.match(regexDate))
                                                    ? itemC.detail.match(regexDate)[0]
                                                    : ''}{' '}
                                                  {itemC.spotName}
                                                </LargeTextStyle>
                                              </ButtonIconGroupStyle>
                                            ))}
                                          </ItemType>
                                        </>
                                      ))}
                                    </>
                                  ))}
                                </ContainType>
                              )}
                            </InfoDetail>
                          </InfoWindow>
                        )}
                      </>
                    )}
                  </Marker>
                );
              });
            })}
        </StyledGoogleMap>

        <StreetViewPanorama
          visible={false}
          options={{
            clickToGo: true,
            disableDefaultUI: true,
          }}
        />
      </div>

      {/* <iframe
          src="https://www.google.com/maps/embed?pb=@13.8692478,100.5741948,17.49z?hl=en-US"
          width="100%"
          height="500"
          style={{ border: 0 }}
          aria-hidden="true"
        /> */}
    </>
  );
};

export default withProps({
  // googleMapURL: `https://maps.googleapis.com/maps/api/js?v=beta&key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geocoder,geometry,drawing,places,visualization`,
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDwKs5XAmH0wjPpvIQYyfB7flm0Et0EzqU&libraries=geocoder,geometry,drawing,places,visualization&language=th`,
  loadingElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />,
})(withScriptjs(withGoogleMap(GoogleMaps)));
