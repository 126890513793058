import { Grid } from '@material-ui/core';
import PaginationTable from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PaginationWrapper } from './styled';
import TableContext from './TableContext';

const StyledPagination = styled.div`
  /* display: block; */

  @media (max-width: 1000px) {
    .MuiToolbar-root {
      display: inline-flex;
    }
  }

  @media (max-width: 425px) {
    .MuiToolbar-gutters {
      padding-left: 0px;
      padding-right: 16px;
    }
  }
  @media (max-width: 400px) {
    .MuiTypography-body2 {
      font-size: 0.675rem;
    }
    .MuiTablePagination-actions {
      margin-left: 4px;
    }
    .MuiTablePagination-select {
      padding-left: 0px;
    }
    .MuiSelect-select.MuiSelect-select {
      font-size: 0.675rem;
    }
  }
`;

const TablePagination: FC = () => (
  <TableContext.Consumer>
    {({ itemsPerPage, page, totalCount, onChangePage, onChangeItemsPerPage, hidePage = false, onHandleChangePage }) =>
      !hidePage && (
        <>
          {' '}
          <br />
          {onHandleChangePage && (
            <PaginationWrapper
              count={Math.ceil(totalCount / itemsPerPage)}
              page={page + 1}
              siblingCount={2}
              onChange={onHandleChangePage}
            />
          )}
          <br />
          <br />
          <PaginationTable
            // rowsPerPageOptions={[5, 10, 25, 100]}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={'ข้อมูลต่อหน้า'}
            // component={'div'}
            component={StyledPagination}
            count={totalCount}
            rowsPerPage={itemsPerPage}
            page={page}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeItemsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeItemsPerPage}
            style={{}}
          />
        </>
      )
    }
  </TableContext.Consumer>
);

export default TablePagination;
