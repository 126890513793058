import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import {
  ISuspectSearchModalAnnotation,
  ISuspectSearchModalAnnotationValidate,
  ISuspectSearchModalBehaviour,
  ISuspectSearchModalBehaviourValidate,
  ISuspectSearchModalSocialValidate,
  ISuspectSearchModalVehicle,
} from '.';
import {
  ISuspectSearch,
  ISuspectSearchAddress,
  ISuspectSearchBank,
  ISuspectSearchForm,
  ISuspectSearchFormValidate,
  ISuspectSearchModalAddress,
  ISuspectSearchModalAddressValidate,
  ISuspectSearchModalBank,
  ISuspectSearchModalBankValidate,
  ISuspectSearchModalSocial,
  ISuspectSearchModalTelephone,
  ISuspectSearchModalTelephoneValidate,
  ISuspectSearchModalVehicleValidate,
  ISuspectSearchPhone,
  ISuspectSearchState,
  ISuspectSearchSuspect,
  ISuspectSearchValidate,
} from './suspect-search.interface';

export const INITIAL_SUSPECT_SEARCH: ISuspectSearch = {
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  searchKeyword: '',
  searchType: null,
  searchKeywordBank: '',
  searchKeywordPhone: '',
  idNumber: null,
  passportNo: null,
  firstName: null,
  lastName: null,
  phoneNo: null,
  address: null,
  provinceID: null,
  province: '',
  createdByFullName: null,
  dateOfBirth: null,
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
};

export const INITIAL_SUSPECT: ISuspectSearchForm = {
  idSuspected: '',
  id: '',
  idNumber: '',
  passportNo: '',
  titleID: null,
  title: '',
  titleName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  nickName: '',
  createdByFullname: '',
  phoneList: [],
  bankList: [],
  addressList: [],
  vehicleList: [],
  socialList: [],
  remarkList: [],
  circumstanceList: [],

  address: '',
  alley: '',
  building: '',
  description: '',
  districtID: null,
  drugTypeID: [],
  orderStatusID: null,
  postalCode: '',
  profileImgUrl: undefined,
  provinceID: null,
  road: '',
  subDistrictID: null,
  suspectLevelID: null,
  suspectTypeID: null,
  appearance: '',
  imageFile: undefined,
  telephoneNo: '',
  typeAddress: null,
  subTypeAddress: null,
  room: '',
  floor: '',
  subBuilding: '',
  detail: '',
  isSuspected: false,
  // address
  addressType: '',
  addressClass: '',
  createdAt: '',
  // phone
  phoneNo: '',
  processStatus: '',
  dateOfBirth: null,

  note: '',
  userID: '',
};

export const INITIAL_SUSPECT_STATE: ISuspectSearchState = {
  selected: {} as ISuspectSearchForm,
  // MODAL
  selectedVehicle: {} as ISuspectSearchModalVehicle,
  selectedSocial: {} as ISuspectSearchModalSocial,

  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  // TABLE
  suspectResponse: INITIAL_DATA_TABLE_TYPE,
  addressResponse: INITIAL_DATA_TABLE_TYPE,
  phoneResponse: INITIAL_DATA_TABLE_TYPE,
  bankResponse: INITIAL_DATA_TABLE_TYPE,
  vehicleResponse: INITIAL_DATA_TABLE_TYPE,
  annotationResponse: INITIAL_DATA_TABLE_TYPE,
  behaviourResponse: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_TELEPHONE: ISuspectSearchModalTelephone = {
  id: '',
  idSuspected: '',
  telephoneNo: '',
  operatorID: null,
  operatorName: '',
  operatorCheckOther: undefined,
  operatorOther: '',
  statusActive: '',
  statusRegistry: '',
  isOwner: undefined,
  isUser: undefined,
  detail: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_ANNOTATION_VALIDATE: ISuspectSearchModalAnnotationValidate = {
  remark: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_BEHAVIOUR_VALIDATE: ISuspectSearchModalBehaviourValidate = {
  circumstance: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_BANK: ISuspectSearchModalBank = {
  id: '',
  idSuspected: '',
  bankID: null,
  bankName: '',
  accountNo: '',
  accountName: '',
  isOwner: undefined,
  isUser: undefined,
  telephoneSMS: '',
  telephoneApplication: '',
  telephonePromptpay: '',
  citizenIDPromptpay: '',
  detail: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_ADDRESS: ISuspectSearchModalAddress = {
  id: '',
  idSuspected: '',
  addressTypeID: null,
  addressTypeName: '',
  addressTypeCheckOther: undefined,
  addressTypeOther: '',
  address: '',
  building: '',
  road: '',
  provinceID: null,
  provinceName: '',
  subDistrictID: null,
  subDistrictName: '',
  districtID: null,
  districtName: '',
  postalCode: '',
  homeNo: '',
  moo: '',
  amphur: '',
  district: '',
  detail: '',
  village: '',
  alley: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_VEHICLE: ISuspectSearchModalVehicle = {
  id: '',
  idSuspected: '',
  carTypeId: null,
  brandId: null,
  modelId: null,
  carColorID: null,
  carColorName: '',
  carColorCheckOther: false,
  carColorOther: '',
  licensePlateNo: '',
  licensePlateNoFirst: '',
  licensePlateNoLast: '',
  licenseProvinceID: null,
  licenseProvinceName: '',
  tankNumber: '',
  engineNumber: '',
  isOwner: false,
  isUser: false,
  detail: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_SOCIAL: ISuspectSearchModalSocial = {
  id: '',
  idSuspected: '',
  socialMediaID: null,
  socialMediaName: '',
  socialMediaCheckOther: undefined,
  socialMediaOther: '',
  name: '',
  detail: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_ANNOTATION: ISuspectSearchModalAnnotation = {
  id: '',
  remark: '',
  idSuspected: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_MODAL_BEHAVIOUR: ISuspectSearchModalBehaviour = {
  id: '',
  circumstance: '',
  idSuspected: '',
  userID: '',
  fileName: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_SUSPECT_SEARCH_VALIDATE: ISuspectSearchValidate = {
  searchKeyword: '',
  searchKeywordPhone: '',
  searchKeywordBank: '',
};

export const INITIAL_SUSPECT_VALIDATE: ISuspectSearchFormValidate = {
  idNumber: '',
  titleID: '',
  firstName: '',
  middleName: '',
  lastName: '',
  address: '',
  building: '',
  alley: '',
  road: '',
  postalCode: '',
  suspectTypeID: '',
  suspectLevelID: '',
  drugTypeID: '',
  orderStatusID: '',
  nickName: '',
  passportNo: '',
  note: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_ADDRESS_VALIDATE: ISuspectSearchModalAddressValidate = {
  address: '',
  districtID: '',
  addressTypeOther: '',
  building: '',
  village: '',
  alley: '',
  road: '',
  postalCode: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_TELEPHONE_VALIDATE: ISuspectSearchModalTelephoneValidate = {
  provinceID: '',
  telephoneNo: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_BANK_VALIDATE: ISuspectSearchModalBankValidate = {
  // bankID: '',
  accountNo: '',
  // accountName: '',
  telephoneSMS: '',
  telephoneApplication: '',
  telephonePromptpay: '',
  citizenIDPromptpay: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_VEHICLE_VALIDATE: ISuspectSearchModalVehicleValidate = {
  // licensePlateNo: '',
  licensePlateNoFirst: '',
  licensePlateNoLast: '',
  carColorOther: '',
  tankNumber: '',
  engineNumber: '',
};

export const INITIAL_SUSPECT_SEARCH_MODAL_SOCIAL_VALIDATE: ISuspectSearchModalSocialValidate = {
  socialMediaID: '',
  socialMediaCheckOther: '',
  socialMediaOther: '',
  name: '',
  detail: '',
};

export const INITIAL_SUSPECT_SEARCH_SUSPECT: ISuspectSearchSuspect = {
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
  searchKeyword: '',
};

export const INITIAL_SUSPECT_SEARCH_ADDRESS: ISuspectSearchAddress = {
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
  searchKeyword: '',
};

export const INITIAL_SUSPECT_SEARCH_PHONE: ISuspectSearchPhone = {
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
  searchKeyword: '',
  searchKeywordPhone: '',
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
};

export const INITIAL_SUSPECT_SEARCH_BANK: ISuspectSearchBank = {
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
  searchKeyword: '',
  searchKeywordBank: '',
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
};
